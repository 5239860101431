import React from "react";
import {
  Box,
  Link,
  TextField,
  Button,
  Typography,
  IconButton,
  Checkbox,
  Modal,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SignupController, {
  Props,
  configJSON
} from "./SignupController.web";
import { logo } from "./assets";
import LeftPanel from "../../../components/src/LeftPanel";
export default class Signup extends SignupController {
 
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      <LeftPanel>
        <ThemeProvider theme={theme}>
          <Box style={webStyle.formWrapper}>
          <Box style={webStyle.form}>
              <Box style={{ marginBottom: "16px" }}>
                <img src={logo} alt="logo" />
              </Box>

              <Typography
                style={{
                  marginBottom: "8px",
                  color: "#0F172A",
                  fontFamily: "Quattrocento",
                  fontSize: "24px",
                  fontWeight: 700,
                }}
              >
                {configJSON.signUpText}
              </Typography>
              <Typography
                style={{
                  marginBottom: "16px",
                  color: "#64748B",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                {configJSON.signUpTextSubTitle}
              </Typography>
              <label style={webStyle.labelText}>
                Email ID<span style={{ color: "red", marginTop: "5px" }}>*</span>
              </label>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: this.state.emailError
                    ? "1px solid red"
                    : "1px solid #C5CBC9",
                  height: "48px",
                  borderRadius: "8px",
                  marginBottom: "11px",
                  marginTop:"1px",
                  width: "380px",
                }}
              >
                <TextField
                  fullWidth
                  value={this.state.email}
                  placeholder="example@gmail.com"
                  data-test-id="txtInput"
                  onChange={this.handleEmailChange}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      height: "",
                      paddingLeft: "10px",
                      width: "100%",
                      border: "none",
                    },
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
              </Box>
              {this.state.emailError && (
               <Box style={{display:'flex',flexDirection:"row"}}><InfoOutlinedIcon style={{ fontSize: "16px", marginRight: "2px", color: "red" }} />
               <Typography style={{ color: "red", fontSize: "12px", marginTop: "auto", lineHeight: "unset" }}>
               
               {this.state.emailError}
             </Typography></Box>
              )}
              <label style={webStyle.labelText}>
                Create Password<span style={{ color: "red" }}>*</span>
              </label>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  border: this.state.passwordError
                    ? "1px solid red"
                    : "1px solid #C5CBC9",
                  height: "48px",
                  borderRadius: "8px",
                  marginBottom: "11px",
                  marginTop: "1px",
                  width:"380px"
                }}
              >
                <TextField
                  style={{ width: "80.1%" }}
                  value={this.state.password}
                  type={this.state.enablePasswordField ? "text" : "password"}
                  onChange={this.handlePasswordChange}
                  placeholder="Password"
                  data-test-id="PasswordInputId"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      border: "none",
                      height: "",
                      width: "100%",
                      paddingLeft: "6px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.setEnablePasswordField}
                  edge="end"
                  style={{ marginRight: "8px" }}
                >
                  {this.state.enablePasswordField ? (
                    <VisibilityOutlined style={{ color: "#A190C2" }} />
                  ) : (
                    <VisibilityOffOutlined style={{ color: "#A190C2" }} />
                  )}
                </IconButton>

              </Box>
              {this.state.passwordError && (
                <Box style={{display:'flex',flexDirection:"row"}}><InfoOutlinedIcon style={{ fontSize: "16px", marginRight: "2px", color: "red" }} />
                <Typography style={{ color: "red", fontSize: "12px", marginTop: "auto", lineHeight: "unset" }}>
                
                {this.state.passwordError}
              </Typography></Box>
                
              )}
              <label style={webStyle.labelText}>
                Confirm Password<span style={{ color: "red" }}>*</span>
              </label>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  border: this.state.confirmPasswordError
                    ? "1px solid red"
                    : "1px solid #C5CBC9",
                  height: "48px",
                  borderRadius: "8px",
                  marginBottom: "11px",
                  marginTop: "1px",
                  width:"380px"
                }}
              >
                <TextField
                  style={{ width: "80.1%" }}
                  value={this.state.confirmPassword}
                  type={this.state.enableConfirmPasswordField ? "text" : "password"}
                  onChange={this.handleConfirmPasswordChange}
                  placeholder="Confirm Password"
                  data-test-id="ConfirmPasswordInputId"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      border: "none",
                      height: "",
                      width: "100%",
                      paddingLeft: "6px",

                    },
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                />
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.setEnableConfirmPasswordField}
                  edge="end"
                  style={{ marginRight: "8px" }}
                >
                  {this.state.enableConfirmPasswordField ? (
                    <VisibilityOutlined style={{ color: "#A190C2" }} />
                  ) : (
                    <VisibilityOffOutlined style={{ color: "#A190C2" }} />
                  )}
                </IconButton>

              </Box>
              {this.state.confirmPasswordError && (
                <Box style={{display:'flex',flexDirection:"row", alignItems: "center"}}><InfoOutlinedIcon style={{ fontSize: "16px", marginRight: "2px", color: "red", marginBottom: "3px" }} />
                <Typography style={{ color: "red", fontSize: "12px" }}>
                
                {this.state.confirmPasswordError}
              </Typography></Box>
              )}
              <Box style={webStyle.criteriaBox}>
                <Box style={{
                  color: this.state.hasUppercase
                    ? "#059669"
                    : this.state.password
                      ? "red"
                      : "#94A3B8",
                      display:"flex",flexDirection:"row",marginTop:"4px"
                }}>

                  <CheckCircleIcon style={{ fontSize: "19px",paddingRight:"3px" }} /><Typography style={webStyle.criteriaText}>At least one Uppercase
                    character (A-Z)</Typography> 
                </Box>
                <Box style={{
                  color: this.state.hasNumber
                    ? "#059669"
                    : this.state.password
                      ? "red"
                      : "#94A3B8",
                      display:"flex",flexDirection:"row",marginTop:"4px"
                }}>
                  <CheckCircleIcon style={{ fontSize: "19px",paddingRight:"3px" }} /><Typography style={webStyle.criteriaText}>At least one numerical
                    (0-9)</Typography>
                </Box>
                <Box style={{
                  color: this.state.hasLowercase
                    ? "#059669"
                    : this.state.password
                      ? "red"
                      : "#94A3B8",
                      display:"flex",flexDirection:"row",marginTop:"4px"
                }}>
                  <CheckCircleIcon style={{ fontSize: "19px",paddingRight:"3px" }} /> <Typography style={webStyle.criteriaText}>At least one Lowercase
                    character (a-z)</Typography>
                </Box>
                <Box style={{
                 
                  color: this.state.hasMinLength
                    ? "#059669"
                    : this.state.password
                      ? "red"
                      : "#94A3B8",
                      display:"flex",flexDirection:"row",marginTop:"4px"
                }}>
                  <CheckCircleIcon style={{ fontSize: "19px",paddingRight:"3px" }} /><Typography style={webStyle.criteriaText}> Minimum 8 characters long</Typography>

                </Box>
              </Box>
              <Box style={{ display: "flex", marginTop: "17%" ,marginLeft:"11px"}}>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.handleCheckboxChange}

                  color="primary"
                  style={{ color: "#A190C2" }}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <Typography
                  style={{
                    color: "#0F172A",
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontWeight: 400,
                    marginTop: "12px",
                  }}
                >
                 " I hereby consent to the{" "}
                  <Link href="/Privacypolicy"  style={{ color: "#A190C2" }}>Privacy Policy</Link> and{" "}
                  <Link  href="/Termsandconditions"style={{ color: "#A190C2" }}>Terms of Use "</Link>
                </Typography>

              </Box>
              {this.state.termsError && (
                <Box style={{marginTop:"1px",marginBottom:"3px",display:"flex",flexDirection:"row",marginLeft:"33px"}}>  
                <Typography style={{ color: "red", fontSize: "13px"}}>
                  <span>{this.state.termsError} </span>
                </Typography></Box>
              )}
              <Button
                data-test-id="btnDoSignup"
                fullWidth
                variant="contained"
                style={{...webStyle.button,textTransform: 'none',marginLeft:"18px",width:"92%",marginTop:"3px"}}
                onClick={() => this.doEmailSignUp()}
              >
                {configJSON.signUpBtnText}
              </Button>
              <Typography
                align="center"
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: "Lato",
                  color: "#0F172A",
                  marginTop: "12px",
                }}
              >
                Already have an account?{" "}
                <Link href="/Signuplogin" style={{ color: "#9176C4" ,textDecoration:"underline",textUnderlinePosition:"under"}}>
                  Login
                </Link>
              </Typography>
            </Box>
          </Box>
        <Modal
          open={this.state.showEmailVerifiedModal}
          onClose={this.handleCloseModal}
          aria-labelledby="email-verified-title"
          aria-describedby="email-verified-description"
        >
          <Box style={{
           position: 'absolute',
           top: '50%',
           left: '50%',
           transform: 'translate(-50%, -50%)',
           width: 400,
           backgroundColor: 'white',
           boxShadow: "24",
           padding: 21,
           borderRadius: 16
          }}>
            <Box display={'flex'} justifyContent={'flex-end'}>
              <IconButton
                aria-label="close"
                onClick={this.handleCloseModal}
                style={{ position: 'absolute', right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box textAlign={'center'}>
              <Typography variant="h6"  style={{ color: "#0F172A", fontSize: "24px", fontWeight: 700,margin:"15px",fontFamily:"Quattrocento" }}>
                Email Verified
              </Typography>
              <Typography style={{...webStyle.sendlinkModal,padding:"11px", wordWrap:"break-word"}}>
                Your email has been successfully verified. Let’s get you started by completing your profile.
              </Typography>
              <Button
                variant="contained"
                onClick={this.handleContinueClick}
                style={{padding: "16px", marginTop: "16px", backgroundColor: "#A190C2", color: "#fff" , borderRadius: "50px", height: "56px",width:"70%", fontWeight: 600, fontFamily: "Lato",textTransform:"none"}}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            boxShadow: "24",
            padding: 19,
            borderRadius: 16
          }}>
            <Box display={'flex'} justifyContent={'flex-end'}>
              <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{ position: 'absolute', right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {!this.state.linkSent && !this.state.linkResent && (
              <Box textAlign={'center'}>
                <Box textAlign={'center'} marginTop={4}>
                  <Typography variant="h6" gutterBottom style={{ color: "#0F172A", fontSize: "24px", fontWeight: 700 }}>Verify Email</Typography>
                </Box>

                <Typography style={webStyle.sendlinkModal}>
                  We have sent a verification link to your email address “<span style={{ color: "#0F172A", fontWeight: 600 }}>{this.state.email}</span>“. Please click on the link to verify your email.
                </Typography>

                <Box style={{ display: "flex", justifyContent: "space-around", marginTop: '16px' }}>
                  <Button variant="outlined" style={{...webStyle.reenterbutton,textTransform:"none"}} onClick={this.clearFields}>Re-enter Email</Button>
                  <Button  data-test-id="btnsendlink" onClick={() => this.handleSendLink()}
                    variant="contained" style={{ border: "1px solid #9176C4", padding: "16px", color: "#FFFFFF", backgroundColor: "#9176C4", borderRadius: "50px", height: "56px", width: "190px", fontWeight: 600, fontFamily: "Lato" ,textTransform:"none"}}>
                    Send Link
                  </Button>
                </Box>
              </Box>
            )}

            {this.state.linkSent && !this.state.linkResent && (
              <Box textAlign={'center'}>
                <Box textAlign={'center'} marginTop={4}>
                  <Typography variant="h6" gutterBottom style={{ color: "#0F172A", fontSize: "24px", fontWeight: 700 }}>Verify Email</Typography>
                </Box>

                <Typography style={webStyle.sendlinkModal}>
                  We have sent a verification link to your email address “<span style={{ color: "#0F172A", fontWeight: 600}}>{this.state.email}</span>“. Please click on the link to verify your email.
                </Typography>

                <Box style={{ display: "flex", justifyContent: "space-around", marginTop: '16px' }}>
                  <Button variant="outlined" style={{...webStyle.reenterbutton,textTransform:"none"}} onClick={this.clearFields}>Re-enter Email</Button>
                  <Button  data-test-id="btnresendlink" onClick={() => this.handleResendLink()}
                    variant="contained" style={{ border: "1px solid #9176C4", padding: "16px", color: "#FFFFFF", backgroundColor: "#9176C4", borderRadius: "50px", height: "56px", width: "190px", fontWeight: 600, fontFamily: "Lato",textTransform:"none" }}>
                    Resend Link
                  </Button>
                </Box>
              </Box>
            )}

            {this.state.linkResent && (
              <Box textAlign={'center'} marginTop={4} >
                <Typography variant="h6" gutterBottom style={{ color: "#0F172A", fontSize: "24px", fontWeight: 700 }}>Resent Link!</Typography>
                <Typography style={webStyle.sendlinkModal}>
                  We have resent the verification link to your email address “<span style={{ color: "#0F172A", fontWeight: 600 }}>{this.state.email}</span>“. Please click on the link to verify your email.
                </Typography>
                <Typography style={webStyle.sendlinkModal}>
                  If you don’t see the email, kindly check your spam folder.
                </Typography>
                <Button  onClick={this.handleClose}
                  variant="contained" style={{ border: "1px solid #9176C4", padding: "16px", color: "#FFFFFF", backgroundColor: "#9176C4", borderRadius: "50px", height: "56px",width:"70%", fontWeight: 600, fontFamily: "Lato", marginTop: '16px' ,textTransform:"none"}}>
                  Okay!
                </Button>
              </Box>
            )}
          </Box>
        </Modal>
      </ThemeProvider>
      </LeftPanel>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  formWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as const,
    backgroundColor: "fff",
    height: "100vh",
  },
  form: {
    maxWidth: "65%",
    margin: "0 auto",
  },
  button: {
    backgroundColor: "#A190C2",
    color: "#fff",
    borderRadius: "46px",
  },
  labelText: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Lato",
    lineHeight: "22px",
    color: "#334155",
  },
  errorBox: {
    display: "flex",
    alignItems: "center",
    color: "red",
    marginBottom: "10px",
  },
  errorIcon: {
    marginRight: "5px",
  },
  errorText: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Lato",
    lineHeight: "22px",
    color: "red",
    display: "flex",
    alignItems: "center",
    bottom: "4px",
  },
  criteriaBox: {
    marginTop: theme.spacing(2.5),
  },
  criteriaText: {
    fontSize: "15px",
    fontWeight: 500,
    fontFamily: "Lato",
    paddingRight:"4px",
    lineHeight:"18px"

  },
  sendlinkModal: {
    fontWeight: 400,
    color: "#64748B",
    fontSize: "18px",
  },
  reenterbutton: {
    border: "1px solid #9176C4",
    padding: "16px",
    color: "#9176C4",
    borderRadius: "50px",
    height: "56px",
    width: "190px",
    fontWeight: 600,
    fontFamily: "Lato"
  }
};
// Customizable Area End

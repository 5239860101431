import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import PersonalLettersController, {
    configJSON,
    IPersonalLetter,
    Props,
} from "./PersonalLettersController.web";
import { 
  Box, 
  Grid, 
  Typography, 
  Button,
  FormLabel,
  Switch,
} from "@material-ui/core";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import { Field, FieldArray, Form, Formik } from "formik";
import { DialogWrapper, theme } from "./LegalInformation.web";
import AdditionalInformationSidebar from "./AdditionalInfoSidebar.web";
import GenericModal from "../../../components/src/GenericModal";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import MediaUpload from "../../../components/src/MediaUpload.web";
// Customizable Area End

export default class PersonalLetters extends PersonalLettersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
        letters,
        openDialog,
    } = this.state;
    const max_personal_letters = this.state.subscriptionData?.features ? this.state.subscriptionData.features.max_personal_letters : 3;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} progress={63} />
            <Box className="container">
              <Box className="AdditionalInformationSidebar">
                <AdditionalInformationSidebar navigation={this.props.navigation} />
              </Box>
              <Box className="personalLettersInfoWrapper">
                <Box className="pageTitle">
                  <Typography data-test-id="pageTitle" variant="h3" className="titleText">
                    {configJSON.personalLettersPageTitle}
                  </Typography>
                  <Typography variant="h3" className="messageText">
                    {configJSON.personalLettersPageSubtitle}
                  </Typography>
                </Box>
                <Box className="personalLettersFormWrapper">
                  <Formik
                    initialValues={letters}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handlePersonalLettersInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="personalLettersForm"
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <FieldArray name="personal_letter" data-test-id="fieldArray">
                          {({ remove, push }) => (
                            values.personal_letter.map((personalLetter: IPersonalLetter, index: number) => {
                              return (
                                <Grid container spacing={2} className="formSectionBackground">
                                  <Grid item xs={12} className="fieldArrayHeaderWrapper">
                                    <Typography data-test-id="fieldArrayTitle" variant="h6">Delegate In-charge of delivering letter</Typography>
                                    {(index === 0 && values.personal_letter.length < max_personal_letters) && (
                                      <Button
                                        className="addLettersBtn"
                                        data-test-id="addLettersBtn"
                                        onClick={() => push({
                                          delegate_id: "",
                                          recipient: "",
                                          letter: "",
                                          have_special_day: false,
                                          special_day: "",
                                          files: [],
                                        })}
                                      >
                                        <AddCircleOutlineIcon className="circleOutlineIcon" />
                                        {configJSON.addLettersBtnText}
                                      </Button>
                                    )}
                                    {index !== 0 && (
                                      <Button
                                        data-test-id="removeBtn"
                                        className="addLettersBtn"
                                        onClick={() => remove(index)}>
                                        <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                        {configJSON.removeBtnText}
                                      </Button>)}
                                  </Grid>
                                  <Grid item lg={4}>
                                    <FormLabel component="label">
                                      {configJSON.delegateFieldLabel}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Field
                                      data-test-id="delegateId"
                                      className="selectField"
                                      name="delegateId"
                                      as={CustomSelect}
                                      setValue={true}
                                      placeholder="Select delegate user"
                                      options={this.state.delegateInCharge}
                                      value={personalLetter.delegate_id}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue(`personal_letter.${index}.delegate_id`, e.target.value)
                                      }}
                                    />
                                    <ErrorMessage name={`personal_letter.${index}.delegate_id`} />
                                  </Grid>
                                  <Grid item lg={4}>
                                    <FormLabel component="label">{configJSON.recipientFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                    <Field
                                      data-test-id="recipientOfLetter"
                                      className="inputField"
                                      name="recipientOfLetter"
                                      as={CustomInput}
                                      size="small"
                                      placeholder="Enter a full name of the recipient"
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue(`personal_letter.${index}.recipient`, e.target.value)
                                      }}
                                      value={personalLetter.recipient}
                                    />
                                    <ErrorMessage name={`personal_letter.${index}.recipient`} />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormLabel component="label">{configJSON.letterFieldLabel}</FormLabel>
                                    <Field
                                      data-test-id="letter"
                                      name="letter"
                                      size="medium"
                                      multiline
                                      style={{ minHeight: "136px !important" }}
                                      as={CustomInput}
                                      placeholder="Please type your letter here or upload."
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setFieldValue(`personal_letter.${index}.letter`, e.target.value)
                                      }
                                      value={personalLetter.letter}
                                    />
                                  </Grid>
                                  <Grid item lg={12}>
                                    <Box className="haveSpecialDayWrapper">
                                      <Typography align="center" className="haveSpecialDayLabel">
                                        {configJSON.haveSpecialDaySwitchLabel}
                                      </Typography>
                                      <Switch
                                        data-test-id="haveSpecialDaySwitch"
                                        color="primary"
                                        checked={personalLetter.have_special_day}
                                        onChange={() =>
                                          this.handleSwitchChange(index, setFieldValue)
                                        }
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    </Box>
                                  </Grid>
                                  {personalLetter.have_special_day && (
                                    <Grid item lg={4}>
                                      <FormLabel component="label">
                                        {configJSON.specialDayFieldLabel}<span style={{ color: "red" }}>*</span>
                                      </FormLabel>
                                      <Field
                                        data-test-id="specialDay"
                                        name="specialDay"
                                        className="inputField"
                                        size="small"
                                        as={CustomInput}
                                        placeholder="Special Day/ Subject"
                                        value={personalLetter.special_day}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                          setFieldValue(`personal_letter.${index}.special_day`, e.target.value)
                                        }
                                      />
                                      <ErrorMessage name={`personal_letter.${index}.special_day`} />
                                    </Grid>
                                  )}
                                  <Grid item lg={12}>
                                    <MediaUpload
                                      data-test-id="mediaUpload"
                                      files={(this.state.files.length > 0 && this.state.files[index]) ? this.state.files[index] : personalLetter.files}
                                      onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, index)}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            })
                          )}
                        </FieldArray>
                        <Box className="btnWrapper">
                            <Button
                              className="btnSize"
                              variant="outlined"
                              onClick={() => this.handleNavigation("CustomForm")}
                               data-test-id="skipForNow"
                            >
                              {configJSON.skipForNowBtnText}
                            </Button>
                            <Button
                              variant="contained"
                              className="btnSize"
                              type="submit"
                              data-test-id="saveAndNextBtn"
                            >
                              {configJSON.saveAndNext}
                            </Button>
                          </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
            <WebFooter handleNavigation={this.handleNavigation} />
          </Wrapper>
        </ThemeProvider>
        <GenericModal
          open={openDialog}
          onClose={this.handleCloseDialog}
          data-test-id="information-added-modal"
        >
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="dialogContentWrapper">
                <Box className="dialogDescriptionWrapper">
                  <Typography
                    variant="h1"
                    align="center"
                    data-test-id="emailSentModalTitle"
                  >
                    {configJSON.infoAddedDialogTitleText}
                  </Typography>
                  <Typography align="center" variant="h2">
                    Your personal letters information has been added successfully to your data. Please select<span className="nextSpan"> Next</span>  to add more information.
                  </Typography>
                </Box>
                <Box className="dialogBtnsWrapper">
                  <Button
                    className="outlinedBtn"
                    variant="outlined"
                    data-test-id="skipForNowDialogBtn"
                    onClick={
                      () => this.handleNavigation("CustomForm")
                    }
                  >
                    {configJSON.skipForNowBtnText}
                  </Button>
                  <Button
                    className="containedBtn"
                    variant="contained"
                    data-test-id="nextSectionBtn"
                    onClick={
                      () => this.handleNavigation("VoiceNotes")
                    }
                  >
                    {configJSON.nextSectionBtnText}
                  </Button>
                </Box>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
      </>
    );
    // Customizable Area End
  }
}


// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .personalLettersInfoWrapper": {
    width: "100%",
  },
  "& .container": {
    display: "flex",
    padding: "50px 72px",
    gap: "72px",
  },
  "& .pageTitle": {
    margin: "16px 0",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  "& .personalLettersFormWrapper": {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "40px",
  },
  "& .inputField": {
    width: "275px",
    height: "48px",
    borderRadius: "8px",
  },
  "& .delegateSelectField": {
    width: "275px !important",
  },
  "& .selectField": {
    width: "275px",
    height: "48px",
    borderRadius: "8px",
    margin: "5px 0px",
    display: "block",
  },
  "& .formSectionBackground": {
    borderRadius: "8px",
    padding: "24px",
    marginBottom: "24px", 
    backgroundColor: "rgba(206, 195, 229, 0.1)",
  },
  "& .fieldArrayHeaderWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .btnSize": {
      width: "158px",
      height: "48px",
  },
  "& .btnWrapper": {
    display: "flex",
    gap: "8px",
    justifyContent: "flex-end",
  },
  "& .addLettersBtn": {
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#9176C4",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  "& .messageText": {
    fontFamily: "Lato",
    fontSize: "16px",
    color: "#1E293B",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .circleOutlineIcon": {
    fontSize: "16px",
    marginRight: "8px",
  },
  "& .haveSpecialDayWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .haveSpecialDayLabel": {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#1E293B", 
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  [theme.breakpoints.down(740)]: {
    "& .container": {
        padding: "28px 16px",
    },
  },
}));
// Customizable Area End

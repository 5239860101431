import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  styled,
} from "@material-ui/core";
import { backimage, earth } from "../../blocks/signuplogin/src/assets";

interface IComponentProps {
  customStyles?: { modal: {} };
  image?: string;

}

const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Quattrocento",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px",
            },
            h2: {
                color: "#475569",
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        }
    }
});

const Wrapper = styled('div')(({ theme }) => ({
    "& .leftPanelGrid": {
        height: "100vh",
        position: "sticky",
        top: 0,
    },
    "& .welcomeDelegateMee": {
        height: "720px",
        backgroundImage: `url(${backimage})`,
        backgroundSize:     "cover",
        backgroundRepeat:   "no-repeat",
        backgroundPosition: "center",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .welcomeContentWrapper": {
        position: 'absolute',
        top: '10%',
    },
    "& .image": {
        Width: '100%',
        height: '720px',
        margin:2,
        padding:2
    },
    "& .earthImgWrapper": {
        display: "flex",
        textAlign: "center", 
        justifyContent: "center",
    },
    "& .welcomeSection": {
        textAlign: 'center',
        paddingTop: 24,
        display: "flex",
        justifyContent: "center",
    },
    "& .welcomeTextWrap": {
        maxWidth: "497px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    [theme.breakpoints.down('sm')]: {
        "& .welcomeDelegateMee": {
            display: "none",
        },
        "& .leftPanelGrid": {
            height: "0",
        },
    },
}));

const LeftPanel: React.FC<IComponentProps> = ({ children }) => {
  return (
      <ThemeProvider theme={theme}>
          <Wrapper>
              <Grid container className="container">
                  <Grid item sm={12} md={6} className="leftPanelGrid">
                      <Box style={{ height: "100%" }}>
                          <Box className="welcomeDelegateMee" sx={{ display: { sm: 'none', md: 'flex' } }}>
                              <Box className="welcomeContentWrapper">
                                  <Box className="earthImgWrapper">
                                      <img src={earth} alt="Earth Globe" />
                                  </Box>
                                  <Box className="welcomeSection">
                                      <Box className="welcomeTextWrap">
                                          <Typography variant="h1">
                                              Welcome to Delegate Mee!
                                          </Typography>
                                          <Typography variant="h2">
                                              Don't let the echoes of your life fade. Craft a personal book that tells your unique story through photos, heartfelt notes, and the sound of your own voice. Delegate two people you trust to have exclusive access to your personal book after you have passed
                                          </Typography>
                                      </Box>
                                  </Box>
                              </Box>
                          </Box>
                      </Box>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    {children}
                  </Grid>
              </Grid>
          </Wrapper>
      </ThemeProvider>
  );
};

export default LeftPanel;
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.selfCare ="Self Care for Grief"
exports.somethingWW = "Something went wrong!"
exports.selfCareDes ="Coping with losing someone can be incredibly painful. It can take time to understand your feelings and adjust to what has happened. But there are things you can do to help yourself cope."
exports.aboutUS = "About US"
exports.getSupportAPIEndPoint = "bx_block_contact_us/supports"
exports.visitSite = "Visit Site"
exports.support = "Support Organisations"
exports.mapDes = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500"
exports.map = "Helping the once who need us!"
exports.supportDes = "Lorem Ipsum is simply dummy text of the printing and typesetting industry"
exports.weBelieve = "We believe, We can help!"
exports.weBelieveDes = "Lorem Ipsum is simply dummy text of the printing and typesetting industry"
// Customizable Area End
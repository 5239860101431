import React from "react";
import {
  Container,
  Grid,
  Box,
  Link,
  TextField,
  // withStyles,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Modal,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close'

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SignuploginController, {
  Props,
  configJSON,
} from "./SignuploginController";
import { logo } from "./assets";
import LeftPanel from "../../../components/src/LeftPanel";
export default class Signuplogin extends SignuploginController {

 
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LeftPanel>
        <ThemeProvider theme={theme}>
        <Box style={webStyle.formWrapper}>
        <Box style={webStyle.form}>
          <Box style={{marginBottom:"20px",marginTop:"10%"}}>
          <img src={logo}
          alt="logo"/>
          </Box>
          
          <Typography style={{marginBottom:"15px",color:"#0F172A",fontFamily:"Quattrocento",fontSize:"20px", fontWeight:700}} data-test-id="welcomeBackText">{configJSON.welcomeBackText}</Typography>
          <Typography style={{marginBottom:"20px",color:"#64748B",fontFamily:"Lato",fontSize:'16px',fontWeight:400 }}>{configJSON.loginSubtitle}</Typography>
          <label  style={webStyle.labelText}>Email ID<span style={{color:'red',marginTop:"5px"}}>*</span></label>
          <Box  style={{display:'flex',flexDirection:'row',alignItems:'center',border: this.state.emailError ? '1px solid red' : '1px solid #C5CBC9',height:'48px',padding:'10px, 24px, 10px, 24px',borderRadius:'8px',marginBottom:'16px',width:"380px"}}>
           
           <TextField
           fullWidth
           value={this.state.email}
         placeholder="example@gmail.com"
         data-test-id="txtInput"
         onChange={this.handleEmailChange}
         InputProps={{
           
           disableUnderline: true,
           
           style: {
             border: 'none',
             height:'',
             paddingLeft:"10px",
             width:"100%"
           },
         }}
         InputLabelProps={{
           shrink: false,
         }}
         
       />   
                
       </Box>
       {this.state.emailError && (
                 <Box style={{display:"flex",flexDirection:"row", alignItems: "center", marginBottom: "16px"}}>
                  <InfoOutlinedIcon style={{fontSize:'16px',marginRight:"2px", color: "red"}}/>
                  <Typography style={{ color: "red", fontSize: "12px", lineHeight: "13px", marginTop: "auto" }}> {this.state.emailError}</Typography>
                 </Box>
            )}
          <label style={webStyle.labelText}>Password<span style={{color:'red'}}>*</span></label>
          <Box  style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-around',border: this.state.passwordError ? '1px solid red' : '1px solid #C5CBC9',height:'48px',padding:'0px, 0px, 0px, 0px',borderRadius:'8px',marginBottom:'16px',marginTop:"5px",width:"380px"}}>
           
           <TextField
           value={this.state.password}
           style={{width:'80.1%'}}
           type={this.state.enableField ? "text" : "password"}
           onChange={this.handlePasswordChange}
         placeholder="Password"
         data-test-id="PasswordInputId"

         InputProps={{
           
           disableUnderline: true,
           
           style: {
             border: 'none',
             height:'',
             width:'100%',
             paddingLeft:"7px",
           },
         }}
         InputLabelProps={{
           shrink: false,
         }}
         
       />   
       <IconButton
                aria-label="toggle password visibility"
                onClick={this.setEnableField}
                edge="end"
                style={{marginRight:"8px"}}
              >
                {this.state.enableField ? (
                   <VisibilityOutlined style={{ color: '#A190C2' }} /> 
                ) : (
                  <VisibilityOffOutlined  style={{ color: '#A190C2' }}/>
                )}
              </IconButton>         
       </Box>
       {this.state.passwordError && (
        <Box style={{display:"flex",flexDirection:"row", alignItems: "center"}}>
            <InfoOutlinedIcon style={{fontSize:'16px',marginRight:"2px",color: "red"}}/>
            <Typography style={{ color: "red", fontSize: "12px", display: "flex", lineHeight: "13px", marginTop: "auto" }}>{this.state.passwordError}</Typography>
        </Box>
            )}
       <Box>
           <Box style={{display:"flex",justifyContent:"flex-end"}}> 
            <Button variant="text" onClick={this.navigateToForgotPass}
            style={{color:'#0F172A',textAlign:'end', textTransform: "none", textDecoration:'underline',textUnderlinePosition:"under",fontSize:"16px",fontWeight:500,fontFamily:"Lato",lineHeight:"24px",marginBottom:"28%"}}>{configJSON.forgotPasswordText}</Button>
</Box>
       </Box>
          <Button fullWidth variant="contained" style={{...webStyle.button,textTransform: 'none',marginLeft:"18px"}} onClick={() =>  this.doEmailLogIn()} data-test-id="btnAddExample" >
            {configJSON.loginBtnText}
          </Button>
          <Typography  align="center" style={{fontSize:"16px",fontWeight:400,fontFamily:"Lato",color:"#0F172A",marginTop:"25px",marginBottom:"28%"}}>
            Don't have an account? <Link href="/Signup"style={{color:"#9176C4",textDecoration:'underline',textUnderlinePosition:"under"}}>Signup</Link>
          </Typography>
        </Box>
        </Box>
      <Modal
          open={this.state.open}
          data-test-id= "closeButton"

          onClose={this.handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box style={{
            backgroundColor: 'white',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            boxShadow: "24",
            padding: 19,
            borderRadius: 16,
            position: 'absolute',
          }}>
            <Box display={'flex'} justifyContent={'flex-end'}>
              <IconButton
                aria-label="close"
                style={{ position: 'absolute', right: 8, top: 8 }}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {!this.state.linkSent  && (
              <Box textAlign={'center'}>
                <Box textAlign={'center'} marginTop={4}>
                  <Typography variant="h6" gutterBottom style={{ color: "#0F172A", fontSize: "24px", fontWeight: 700 }}>{configJSON.verifyEmailText}</Typography>
                </Box>

                <Typography style={webStyle.sendlinkModal}>
                  We have sent a verification link to your email address “<span style={{ color: "#0F172A", fontWeight: 600 }}>{this.state.email}</span>“. Please click on the link to verify your email.
                </Typography>

                <Box style={{ display: "flex", justifyContent: "space-around", marginTop: '16px' }}>
                  <Button variant="outlined" style={{...webStyle.reenterbutton,textTransform:"none"}} data-test-id="btnreenter" onClick={this.clearFields}>{configJSON.reEnterBtnText}</Button>
                  <Button  data-test-id="btnsendlink" onClick={() => this.handleSendLink()}
                    variant="contained" style={{ border: "1px solid #9176C4", padding: "16px", color: "#FFFFFF", backgroundColor: "#9176C4", borderRadius: "50px", height: "56px", width: "190px", fontWeight: 600, fontFamily: "Lato",textTransform:"none" }}>
                    {configJSON.resendLinkBtnText}
                  </Button>
                </Box>
              </Box>
            )}

            {this.state.linkSent && (
              <Box textAlign={'center'} marginTop={4} >
                <Typography variant="h6" gutterBottom style={{ color: "#0F172A", fontSize: "24px", fontWeight: 700 }}>Resent Link!</Typography>
                <Typography style={webStyle.sendlinkModal}>
                  We have resent the verification link to your email address “<span style={{ color: "#0F172A", fontWeight: 600 }}>{this.state.email}</span>“. Please click on the link to verify your email.
                </Typography>
                <Typography style={webStyle.sendlinkModal}>
                  If you don’t see the email, kindly check your spam folder.
                </Typography>
                <Button  onClick={this.handleClose}
                  variant="contained" style={{ padding: "16px", color: "#FFFFFF", backgroundColor: "#9176C4",textTransform:"none", borderRadius: "51px", height: "56px",width:"70%", fontWeight: 601, fontFamily: "Lato", marginTop: '16px',border: "1px solid #9176C4" }}>
                  Okay!
                </Button>
              </Box>
            )}
          </Box>
        </Modal>
      </ThemeProvider>
      </LeftPanel>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  formWrapper: {
    backgroundColor: "fff",
    height: "100vh",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    maxWidth: '400px',
    margin: '0 auto',
  },
  button: {
    backgroundColor: '#A190C2',
    color: '#fff',
    borderRadius:'46px',
    width:"90%",  
  },
  labelText:{
  fontSize:'14px',
  fontWeight:400,
  fontFamily:'Lato',
  lineHeight:'22px',
  color:"#334155",

  },
  sendlinkModal: {
    fontWeight: 400,
    color: "#64748B",
    fontSize: "18px",
  },
  reenterbutton: {
    border: "1px solid #9176C4",
    padding: "16px",
    color: "#9176C4",
    borderRadius: "50px",
    height: "56px",
    width: "190px",
    fontWeight: 600,
    fontFamily: "Lato"
  }
};
// Customizable Area End

import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import PetsAndAnimalsController, {
    configJSON,
    IPetDetailsAttributes,
    IPetsInformation,
    Props,
} from "./PetsAndAnimalsController.web";
import { 
  Box, 
  Grid, 
  Typography, 
  Button,
  FormLabel, 
  RadioGroup, 
  FormControlLabel,
  IconButton,
  Badge,
  Avatar,
  FormHelperText,
} from "@material-ui/core";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import { Field, FieldArray, Form, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import { DialogWrapper, styledMyLocationTwoToneIcon, StyledRadio, theme } from "./LegalInformation.web";
import AdditionalInformationSidebar from "./AdditionalInfoSidebar.web";
import GenericModal from "../../../components/src/GenericModal";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import { countries } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
import MediaUpload from "../../../components/src/MediaUpload.web";
import PhoneInput from "react-phone-input-2";
// Customizable Area End

export default class PetsAndAnimals extends PetsAndAnimalsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPetDetailsForm = (
    values: FormikValues, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
      const max_pets = this.state.subscriptionData?.features ? this.state.subscriptionData.features.max_pets : 2;
    return(
        <Grid item xs={12}>
            <Box p={2}>
                <FormLabel component="label">
                    {configJSON.noOfPetsFieldLabel}
                </FormLabel>
                <Field
                    data-test-id="petNumber"
                    className="selectField"
                    name="petNumber"
                    as={CustomSelect}
                    placeholder="Select no. of pets"
                    options={[
                        { value: 1, name: "1" },
                        { value: 2, name: "2" },
                        { value: 3, name: "3" },
                        { value: 4, name: "4" },
                    ]}
                    value={values.pet_number}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`pet_number`, e.target.value)
                    }}
                />
            </Box>
            <FieldArray name="pet_details_attributes" data-test-id="fieldArray">
                {({ remove, push }) => (
                    values.pet_details_attributes.map((petdetails: IPetDetailsAttributes, index: number) => {
                        return (
                            <Grid container spacing={2} className="formSectionBackground">
                                <Grid item xs={12} className="fieldArrayHeaderWrapper">
                                    <Typography data-test-id="petDetailsFormTitle" variant="h6">{configJSON.petDetailsFormTitle}</Typography>
                                    {(index === 0 && values.pet_details_attributes.length < max_pets) && (
                                        <Button
                                            className="addMoreBtn"
                                            data-test-id="addMoreBtn"
                                            onClick={() => push({
                                                pet_photo: null,
                                                pet_name: "",
                                                date_of_birth: "",
                                                breed: "",
                                                requirement: "",
                                                address_line_1: "",
                                                address_line_2: "",
                                                landmark: "",
                                                city: "",
                                                country: "",
                                                post_code: "",
                                                files: [],
                                            })}
                                        >
                                            <AddCircleOutlineIcon className="circleOutlineIcon" />
                                            {configJSON.addMoreBtnText}
                                        </Button>
                                    )}
                                    {index !== 0 && (
                                        <Button
                                            data-test-id="removeBtn"
                                            className="addMoreBtn"
                                            onClick={() => remove(index)}>
                                            <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                            {configJSON.removeBtnText}
                                        </Button>)}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <input
                                            data-test-id="petPhoto"
                                            id={`petPhotoInput-${index}`}
                                            type="file"
                                            name="petPhoto"
                                            accept="image/*"
                                            onChange={(event) =>
                                                this.handlePetPhoto(event, setFieldValue, index)
                                            }
                                            style={{ display: 'none' }}
                                        />
                                        <label htmlFor={`petPhotoInput-${index}`}>
                                            <IconButton component="span" style={{ paddingLeft: '0' }}>
                                                {this.state.petPhotos[index] || values.photo ? (
                                                    <Badge
                                                        className="petBadge"
                                                        overlap="circular"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <Avatar
                                                            className="petPhotoAvatar"
                                                            style={{
                                                                margin: '5px 0 10px 0'
                                                            }}
                                                            alt="petPhoto"
                                                            src={this.state.petPhotos[index] as string}
                                                        />
                                                    </Badge>
                                                ) : (
                                                    <svg fill="none" width="62" height="62" viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" y="0.5" width="61" height="61" rx="30.5" fill="#F8FFF0" />
                                                        <rect x="0.5" y="0.5" width="61" height="61" rx="30.5" stroke="#ACCC86" />
                                                        <path fill="#ACCC86" d="M33.12 24L34.95 26H39V38H23V26H27.05L28.88 24H33.12ZM34 22H28L26.17 24H23C21.9 24 21 24.9 21 26V38C21 39.1 21.9 40 23 40H39C40.1 40 41 39.1 41 38V26C41 24.9 40.1 24 39 24H35.83L34 22ZM31 29C32.65 29 34 30.35 34 32C34 33.65 32.65 35 31 35C29.35 35 28 33.65 28 32C28 30.35 29.35 29 31 29ZM31 27C28.24 27 26 29.24 26 32C26 34.76 28.24 37 31 37C33.76 37 36 34.76 36 32C36 29.24 33.76 27 31 27Z" />
                                                    </svg>
                                                )}
                                            </IconButton>
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">
                                        {configJSON.petNameFieldLabel}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Field
                                        data-test-id="petName"
                                        className="inputField"
                                        name="petName"
                                        as={CustomInput}
                                        size="small"
                                        placeholder="Enter your pet name"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`pet_details_attributes.${index}.pet_name`, e.target.value)
                                        }}
                                        value={petdetails.pet_name}
                                    />
                                    <ErrorMessage name={`pet_details_attributes.${index}.pet_name`} />
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">{configJSON.dateOfBirthLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                    <Field
                                        data-test-id="petDOB"
                                        name="petDOB"
                                        className="inputField"
                                        size="small"
                                        as={CustomDatePicker}
                                        placeholder="DD/MM/YYYY"
                                        endIcon={
                                            this.calenderIcon
                                        }
                                        onChange={(date: Date) =>
                                            setFieldValue(`pet_details_attributes.${index}.date_of_birth`, date.toLocaleDateString("en-GB"))
                                        }
                                        value={petdetails.date_of_birth}
                                    />
                                    <ErrorMessage name={`pet_details_attributes.${index}.date_of_birth`} />
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">
                                        {configJSON.petBreedfieldLabel}
                                    </FormLabel>
                                    <Field
                                        data-test-id="PetBreed"
                                        className="inputField"
                                        name="PetBreed"
                                        as={CustomInput}
                                        size="small"
                                        placeholder="Enter you pet breed"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`pet_details_attributes.${index}.breed`, e.target.value)
                                        }}
                                        value={petdetails.breed}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="label">{configJSON.petsRequirementfieldLabel}</FormLabel>
                                    <Field
                                        data-test-id="petsRequirements"
                                        name="petsRequirements"
                                        size="medium"
                                        multiline
                                        style={{ minHeight: "84px !important" }}
                                        as={CustomInput}
                                        placeholder="Enter special details/instructions"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setFieldValue(`pet_details_attributes.${index}.requirement`, e.target.value)
                                        }
                                        value={petdetails.requirement}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography data-test-id="petAddressTitle" variant="h6">{configJSON.petAddressTitle}</Typography>
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">
                                        {configJSON.addressLine1Label}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Field
                                        data-test-id="petAddressLine1"
                                        name="petAddressLine1"
                                        className="inputField"
                                        size="small"
                                        startIcon={styledMyLocationTwoToneIcon}
                                        as={CustomInput}
                                        placeholder="House number / Flat no."
                                        value={petdetails.address_line_1}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setFieldValue(`pet_details_attributes.${index}.address_line_1`, e.target.value)
                                        }
                                    />
                                    <ErrorMessage name={`pet_details_attributes.${index}.address_line_1`} />
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">
                                        {configJSON.addressLine2Label}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Field
                                        data-test-id="petAddressLine2"
                                        name="petAddressLine2"
                                        className="inputField"
                                        size="small"
                                        as={CustomInput}
                                        placeholder="Street and Locality"
                                        value={petdetails.address_line_2}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setFieldValue(`pet_details_attributes.${index}.address_line_2`, e.target.value)
                                        }
                                    />
                                    <ErrorMessage name={`pet_details_attributes.${index}.address_line_2`} />
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">
                                        {configJSON.landmarkLabel}
                                    </FormLabel>
                                    <Field
                                        data-test-id="petLandmark"
                                        name="petLandmark"
                                        className="inputField"
                                        size="small"
                                        as={CustomInput}
                                        placeholder="Landmark"
                                        value={petdetails.landmark}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setFieldValue(`pet_details_attributes.${index}.landmark`, e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">
                                        {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Field
                                        data-test-id="petTownCity"
                                        name="petTownCity"
                                        className="inputField"
                                        size="small"
                                        as={CustomInput}
                                        placeholder="City"
                                        value={petdetails.city}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setFieldValue(`pet_details_attributes.${index}.city`, e.target.value)
                                        }
                                    />
                                    <ErrorMessage name={`pet_details_attributes.${index}.city`} />
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">
                                        {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Field
                                        data-test-id="petCountry"
                                        className="selectField"
                                        name="petCountry"
                                        as={CustomSelect}
                                        placeholder="Country"
                                        options={countries}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`pet_details_attributes.${index}.country`, e.target.value)
                                        }}
                                        value={petdetails.country}
                                    />
                                    <ErrorMessage name={`pet_details_attributes.${index}.country`} />
                                </Grid>
                                <Grid item lg={4}>
                                    <FormLabel component="label">
                                        {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Field
                                        data-test-id="petPostCode"
                                        name="petPostCode"
                                        className="inputField"
                                        size="small"
                                        as={CustomInput}
                                        placeholder="Post Code"
                                        value={petdetails.post_code}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setFieldValue(`pet_details_attributes.${index}.post_code`, e.target.value)
                                        }
                                    />
                                    <ErrorMessage name={`pet_details_attributes.${index}.post_code`} />
                                </Grid>
                                <Grid item lg={12}>
                                    <MediaUpload
                                        data-test-id="mediaUpload"
                                        files={(this.state.files.length > 0 && this.state.files[index]) ? this.state.files[index] : petdetails.files}
                                        onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, index)}
                                    />
                                </Grid>
                            </Grid>
                        );
                    })
                )}
            </FieldArray>
        </Grid>
  )};

  renderCaretakerDetailsForm = (
    values: FormikValues, 
    errors: FormikErrors<IPetsInformation>, 
    touched: FormikTouched<IPetsInformation>, 
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
        return(
            <Grid item xs={12} className="formSectionBackground">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography data-test-id="careTakerFormTitle" variant="h6">{configJSON.careTakerFormTitle}</Typography>
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.caretakerFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                    data-test-id="caretakerId"
                    className="selectField"
                    name="caretakerId"
                    as={CustomSelect}
                    setValue={true}
                    placeholder="Select caretaker"
                    options={this.state.delegateInCharge}
                    value={values.caretaker_attributes.caretaker_id}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue("caretaker_attributes.caretaker_id", e.target.value)
                    }}
                    error={touched.caretaker_attributes?.caretaker_id && errors.caretaker_attributes?.caretaker_id}
                    errorMsg={errors.caretaker_attributes?.caretaker_id}
                />
                </Grid>
                { values.caretaker_attributes.caretaker_id === "0" && (
                        <Grid item lg={4}>
                            <FormLabel component="label">{configJSON.caretakerNameFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                            <Field
                                data-test-id="caretakerName"
                                name="caretakerName"
                                className="inputField"
                                size="small"
                                as={CustomInput}
                                placeholder="Enter full name"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue("caretaker_attributes.full_name", e.target.value)
                                }
                                value={values.caretaker_attributes.full_name}
                                error={touched.caretaker_attributes?.full_name && errors.caretaker_attributes?.full_name}
                                errorMsg={errors.caretaker_attributes?.full_name}
                            />
                        </Grid>
                )}
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.phoneNumberLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Box>
                        <PhoneInput
                        data-test-id="phoneNumber"
                        placeholder={configJSON.phoneNumberPlaceholder}
                        country={'gb'}
                        value={values.caretaker_attributes.full_phone_number}
                        onChange={(value) =>
                            setFieldValue("caretaker_attributes.full_phone_number", value)
                        }
                        inputStyle={{
                            height: '48px',
                            width: '275px',
                            borderRadius: '8px',
                            border: '1px solid #e0e0e0',
                            padding: '10px 45px',
                        }}
                        buttonStyle={{
                            border: '1px solid #e0e0e0',
                            borderTopLeftRadius: '8px',
                            borderBottomLeftRadius: '8px',
                            padding: '2px',
                        }}
                        containerStyle={{
                            width: '275px',
                            height: '48px',
                            margin: '5px 0',
                        }}
                        inputProps={{
                            name: 'phone',
                            autoFocus: true,
                            required: true,
                        }}
                        />
                        {touched.caretaker_attributes?.full_phone_number && errors.caretaker_attributes?.full_phone_number &&
                        <FormHelperText error className="errorMsg">
                            <InfoOutlinedIcon className="errorIcon" />
                            <span>{errors.caretaker_attributes?.full_phone_number}</span>
                        </FormHelperText>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        );
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
        pet_information,
        showPetDetailsForm,
        showCaretakerDetailsForm,
        openDialog,
    } = this.state;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <WebHeader navigation={this.props.navigation} progress={54} />
            <Box className="container">
              <Box className="AdditionalInformationSidebar">
                <AdditionalInformationSidebar navigation={this.props.navigation} />
              </Box>
              <Box className="petInfoWrapper">
                <Box className="pageTitle">
                    <Typography data-test-id="pageTitle" variant="h3" className="titleText">
                        {configJSON.petsPageTitle}
                    </Typography>
                    <Typography variant="h3" className="messageText">
                        <InfoOutlinedIcon className="infoIcon" />
                        <span>{configJSON.messageText}</span>
                    </Typography>
                </Box>
                <Box className="petFormWrapper">
                  <Formik
                    initialValues={pet_information}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handlePetsInformationsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="petsAndAnimalsForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Box className="petFormWrapper">
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                    {configJSON.petsAndAnimalsQuestion1}
                                </FormLabel>
                                <RadioGroup
                                  className="radioGroup"
                                  data-test-id="havePets"
                                  aria-label="havePets"
                                  name="havePets"
                                  value={values.have_pets}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("have_pets", e.target.value);
                                    this.setState({ showPetDetailsForm: !showPetDetailsForm });
                                  }
                                  }
                                >
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </Grid>
                              { showPetDetailsForm && (
                                    this.renderPetDetailsForm(values, setFieldValue)
                              )}
                              <Grid item xs={12}>
                                <FormLabel component="label" className="formRadioLabel">
                                    {configJSON.petsAndAnimalsQuestion2}
                                </FormLabel>
                                <RadioGroup
                                  className="radioGroup"
                                  data-test-id="haveResponsible"
                                  aria-label="haveResponsible"
                                  name="haveResponsible"
                                  value={values.have_responsible}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("have_responsible", e.target.value);
                                    this.setState({ showCaretakerDetailsForm: !showCaretakerDetailsForm });
                                  }
                                  }
                                >
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="1"
                                    control={<StyledRadio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    className="radioLabel"
                                    value="0"
                                    control={<StyledRadio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </Grid>
                              { showCaretakerDetailsForm && (
                                    this.renderCaretakerDetailsForm(values, errors, touched, setFieldValue)
                              )}
                            </Grid>
                          </Box>
                          <Box className="btnWrapper">
                            <Button
                              className="btnSize"
                              variant="outlined"
                              onClick={
                                  () => this.handleNavigation("CustomForm")
                                }
                               data-test-id="skipForNow"
                            >
                              {configJSON.skipForNowBtnText}
                            </Button>
                            <Button
                              type="submit"
                              className="btnSize"
                              variant="contained"
                              data-test-id="saveAndNextBtn"
                            >
                              {configJSON.saveAndNext}
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
            <WebFooter handleNavigation={this.handleNavigation} />
          </Wrapper>
        </ThemeProvider>
        <GenericModal 
            open={openDialog} 
            data-test-id="information-added-modal" 
            onClose={this.handleCloseDialog}
        >
          <ThemeProvider theme={theme}>
            <DialogWrapper>
              <Box className="dialogContentWrapper">
                <Box className="dialogDescriptionWrapper">
                  <Typography 
                    align="center" 
                    variant="h1" 
                    data-test-id="emailSentModalTitle"
                  >
                    {configJSON.infoAddedDialogTitleText}
                  </Typography>
                  <Typography align="center" variant="h2">
                    Your pets information has been added successfully to your data. Please select<span className="nextSpan"> Next</span>  to add more information.
                  </Typography>
                </Box>
                <Box className="dialogBtnsWrapper">
                  <Button
                    data-test-id="skipForNowDialogBtn"
                    className="outlinedBtn"
                    variant="outlined"
                    onClick={() => this.handleNavigation("CustomForm")}
                  >
                    {configJSON.skipForNowBtnText}
                  </Button>
                  <Button
                    data-test-id="nextSectionBtn"
                    className="containedBtn"
                    variant="contained"
                    onClick={
                        () => this.handleNavigation("PersonalLetters")
                    }
                  >
                    {configJSON.nextSectionBtnText}
                  </Button>
                </Box>
              </Box>
            </DialogWrapper>
          </ThemeProvider>
        </GenericModal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .petInfoWrapper": {
    width: "100%",
  },
  "& .container": {
    padding: "50px 72px",
    display: "flex",
    gap: "72px",
  },
  "& .pageTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0",
  },
  "& .checkboxWrapper": {
    display: 'flex',
    alignItems: 'center',
  },
  "& .pdfLabel": {
    color: "#0F172A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  "& .petFormWrapper": {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "40px",
  },
  "& .textMargin": {
    margin: "8px 0px",
  },
  "& .inputField": {
    borderRadius: "8px",
    height: "48px",
    width: "275px",
  },
  "& .delegateSelectField": {
    width: "275px !important",
  },
  "& .selectField": {
    margin: "5px 0px",
    borderRadius: "8px",
    width: "275px",
    height: "48px",
    display: "block",
  },
  "& .radioGroup": {
    display: 'flex',
    marginLeft: "16px",
    gap: "35px",
    flexDirection: 'row',
  },
  "& .radioLabel": {
    fontFamily: "Lato",
    fontSize: "16px",
    color: '#1E293B',
    lineHeight: "22px",
    fontWeight: 400,
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .formRadioLabel": {
    fontSize: "16px",
  },
  "& .formSectionBackground": {
      padding: "24px",
      borderRadius: "8px",
      backgroundColor: "rgba(206, 195, 229, 0.1)",
      marginBottom: "24px", 
  },
  "& .btnSize": {
      width: "158px",
      height: "48px",
  },
  "& .fieldArrayHeaderWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .btnWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
  },
  "& .addMoreBtn": {
      textTransform: "none",
      fontSize: "14px",
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#9176C4",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
    '&:hover': {
        textDecoration: "underline",
        backgroundColor: "transparent",
    },
  },
  "& .messageText": {
    fontFamily: "Lato",
    fontSize: "14px",
    color: "#9176C4",
    fontWeight: 500,
    lineHeight: "18px",
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
  "& .circleOutlineIcon": {
    marginRight: "8px",
    fontSize: "16px",
  },
  "& .infoIcon": {
    fontSize: "20px",
  },
  "& .petPhotoAvatar": {
    width: "72px",
    height: "72px",
  },
  "& .errorIcon": {
      width: "16px", 
      height: "16px",
      marginBottom: "3px",
      marginRight: "2px",
  },
  "& .errorMsg": {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#DC2626",
    fontSize: "12px",
  },
  [theme.breakpoints.down(740)]: {
    "& .container": {
        padding: "28px 16px",
    },
  },
}));
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getLastPartOfURL, sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export interface IAboutMe {
    have_physcial_information: boolean;
    have_favourite_information: boolean;
    have_hobbies_information: boolean;
    have_dream_information: boolean;
    have_proud_moments: boolean;
    have_achievement_information: boolean;
    have_cv_information: boolean;
};

export interface IFuneral {
    have_funeral_basic_information: boolean;
    have_attire: boolean;
    have_hair_and_beauty: boolean;
    have_further_items: boolean;
};

export interface IUserDetails {
    id: number;
    user_id: string;
    email: string;
    full_name: string;
    photo_url: string;
    activated: boolean;
    term_and_condition: boolean;
    created_at: string;
    ["have_created_profile?"]: boolean;
    ["have_delegate_members?"]: boolean;
    count_delegate: number;
    ["have_subscription?"]: boolean;
    ["have_legal_information?"]: boolean;
    ["have_medical_information?"]: boolean;
    ["have_investment_information?"]: boolean;
    ["have_pet_information?"]: boolean;
    ["have_family_detail?"]: boolean;
    ["have_friend_detail?"]: boolean;
    ["have_personal_letters?"]: boolean;
    ["have_voice_notes?"]: boolean;
    about_me: IAboutMe;
    funeral: IFuneral;
    ["have_after_wish?"]: boolean;
};

export interface ILoggedInUserApiResponse {
    id: string;
    type: string;
    attributes: IUserDetails;
}

export interface IFeatures {
  max_delegates: number;
  max_wills: number;
  max_medical_documents: number;
  max_investment_documents: number;
  max_pets: number;
  max_closest_friends: number;
  max_group_memories: number;
  max_family_members: number | null;
  max_memories: number;
  max_memory_uploads: number;
  max_festival_memories: number;
  max_voice_notes: number;
  max_personal_letters: number;
  max_favourite_outfits: number;
  max_hobbies: number;
  max_achievements: number;
  max_hair_uploads: number;
  max_makeup_uploads: number;
  max_nails_uploads: number;
  max_map_pins: number;
  is_cv_upload: boolean;
  is_death_delegates_will_receive: boolean;
}

export interface IAttributes {
  name: string;
  price: string;
  plan_type: string;
  currency_name: string;
  description: string;
  image_link: string;
  activated: boolean;
  valid_up_to: string;
  features: IFeatures;
}

export interface IUserSubscription {
    id: string;
    type: string;
    attributes: IAttributes;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
  // Customizable Area Start
    profileData: IUserDetails | null;
    disabledTabsId: number[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdditionalInfoSidebarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  callGetLoggedInUserApiId: string = "";
  callGetActiveSubscriptionPlan: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

      this.state = {
          profileData: null,
          disabledTabsId: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getLoggedInUserDetails();
    this.getActiveSubscriptionPlan();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetLoggedInUserApiId]: this.handleGetLoggedInUserResponse,
      [this.callGetActiveSubscriptionPlan]: this.handleGetSubscriptionPlanResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getLoggedInUserDetails = async () => {
    const token = await getStorageData("token");

    if(token){
        this.callGetLoggedInUserApiId = sendAPIRequest(
            configJSON.getLoggedInUserApiEndPoint,
            {
              method: configJSON.httpGetMethod,
              headers: {
                  token,
                },
            }
        );
    }
  };

  handleGetLoggedInUserResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    const response = responseJSON as {
      meta?: { message: string };
      data?: ILoggedInUserApiResponse;
    };

    if (response.data) {
      this.setState({ 
        profileData: response.data.attributes,
     });

     const currentPage: string = getLastPartOfURL();
     const profileData = this.state.profileData;
    const fieldsToCheck = [
      { key: "have_legal_information?", page: "LegalInformation", tabId: 1 },
      { key: "have_medical_information?", page: "MedicalInformation", tabId: 2 },
      { key: "have_investment_information?", page: "InvestmentsAndFunds", tabId: 3 },
      { key: "have_family_detail?", page: "FamilyDetails", tabId: 4 },
      { key: "have_friend_detail?", page: "FriendsDetails", tabId: 5 },
      { key: "have_pet_information?", page: "PetsAndAnimals", tabId: 6 },
      { key: "have_personal_letters?", page: "PersonalLetters", tabId: 7 },
      { key: "have_voice_notes?", page: "VoiceNotes", tabId: 8 },
      { key: "have_after_wish?", page: "AfterWishes", tabId: 11 }
    ];

    fieldsToCheck.forEach(({ key, page, tabId }) => {
      this.handleFieldCheck(profileData, key, currentPage, page, tabId);
    });

     // Check AboutMe and Funeral fields
      if (this.state.profileData?.about_me && Object.values(this.state.profileData?.about_me).every(value => value === true)) {
        if (currentPage === "AboutMe") {
          this.handleNavigation("CustomForm");
        }
        this.setState({ disabledTabsId: [...this.state.disabledTabsId, 9] });
      }
      if (this.state.profileData?.funeral && Object.values(this.state.profileData?.funeral).every(value => value === true)) {
        if (currentPage === "YourFuneral") {
          this.handleNavigation("CustomForm");
        }
        this.setState({ disabledTabsId: [...this.state.disabledTabsId, 10] });
      }
    }
  };

  // Helper function to handle individual field checks
  handleFieldCheck = (
    profileData: any,
    key: string,
    currentPage: string,
    page: string,
    tabId: number
  ) => {
    if (profileData?.[key]) {
      if (currentPage === page) {
        this.handleNavigation("CustomForm");
      }
      this.setState({ disabledTabsId: [...this.state.disabledTabsId, tabId] });
    }
  };

  getActiveSubscriptionPlan = async () => {
    const token = await getStorageData("token");

    if(token){
        this.callGetActiveSubscriptionPlan = sendAPIRequest(
            configJSON.getActiveSubscriptionPlanApiEndPoint,
            {
              method: configJSON.httpGetMethod,
              headers: {
                  token,
                },
            }
        );
    }
  };

  handleGetSubscriptionPlanResponse = async (
    responseJSON: Record<string, unknown>
  ) => {
    const response = responseJSON as {
      meta?: { message: string };
      data?: IUserSubscription;
    };

    if (response.data) {
      await setStorageData("active_subscription", JSON.stringify(response.data.attributes));
    }
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
